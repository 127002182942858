<template>
  <div class="table-mundial-group">
    <div class="table-mundial-group__grouptitle" v-if="header">{{ data.name }}</div>
    <div class="table-mundial-group__head">
      <div class="table-mundial-group__head__div-table-col-head position" align="center">
        POS
      </div>
      <div class="table-mundial-group__head__div-table-col-head teamtable">
        EQUIPO
      </div>
      <div class="table-mundial-group__head__div-table-col-head jj">JJ</div>
      <div class="table-mundial-group__head__div-table-col-head jg">JG</div>
      <div class="table-mundial-group__head__div-table-col-head je">JE</div>
      <div class="table-mundial-group__head__div-table-col-head jp">JP</div>
      <div class="table-mundial-group__head__div-table-col-head gf movil-game-flag-g">
        GF
      </div>
      <div class="table-mundial-group__head__div-table-col-head gc movil-game-flag-g">
        GC
      </div>
      <div class="table-mundial-group__head__div-table-col-head dg movil-game-flag-g">
        DG
      </div>
      <div class="table-mundial-group__head__div-table-col-head pts">PTS</div>
      <template v-if="header">
        <div class="table-mundial-group__head__div-table-col-head lastgames movil-game-flag-lastgame">
          ÚLTIMOS JUEGOS
        </div>
        <div class="table-mundial-group__head__div-table-col-head lastgames movil-game-flag-lastgame-movil">
          U. JUEGOS
        </div>
      </template>
      <template v-else>
        <div class="table-mundial-group__head__div-table-col-head lastgames ">
          U. JUEGOS
        </div>
      </template>

      <div class="table-mundial-group__head__div-table-col-head prox">PROX</div>
    </div>
    <!-- nombre nombre-->
    <div class="table-mundial-group__div-table">
      <template v-if="data">
        <template v-for="(dataGroup, index) in data.data">
          <div
            class="table-mundial-group__div-table-row"
            :key="index"
            :class="teamselect == dataGroup.team_id ? 'color-green' : ''"
          >
            <div class="table-mundial-group__div-table-row__div-table-col position">
              {{ dataGroup.table_position }}.
            </div>
            <div class="table-mundial-group__div-table-row__div-table-col logo">
              <img
                :src="'https://az755631.vo.msecnd.net/teams-80/' + dataGroup.team_id + '.png'"
                width="22"
                :alt="dataGroup.team_name"
              />
            </div>
            <div
              class="table-mundial-group__div-table-row__div-table-col teamtable"
              @click="goToTeamProfile(dataGroup.team_id)"
            >
              {{ dataGroup.team_short_name }}
            </div>
            <div class="table-mundial-group__div-table-row__div-table-col jj">
              {{ dataGroup.games_played }}
            </div>
            <div class="table-mundial-group__div-table-row__div-table-col jg">
              {{ dataGroup.games_won }}
            </div>
            <div class="table-mundial-group__div-table-row__div-table-col je">
              {{ dataGroup.games_tied }}
            </div>
            <div class="table-mundial-group__div-table-row__div-table-col jp">
              {{ dataGroup.games_lost }}
            </div>
            <div class="table-mundial-group__div-table-row__div-table-col gf movil-game-flag-g">
              {{ dataGroup.goals_scored }}
            </div>
            <div class="table-mundial-group__div-table-row__div-table-col gc movil-game-flag-g">
              {{ dataGroup.goals_received }}
            </div>
            <div class="table-mundial-group__div-table-row__div-table-col dg movil-game-flag-g">
              {{ dataGroup.goals_difference }}
            </div>
            <div class="table-mundial-group__div-table-row__div-table-col pts">{{ dataGroup.points }}</div>
            <div class="table-mundial-group__div-table-row__div-table-col lastgames">
              <template v-if="dataGroup.last_five_games.length > 0">
                <template v-for="(game, index) in dataGroup.last_five_games">
                  <div
                    :key="index"
                    class="table-mundial-group__div-table-row__div-table-col__game red"
                    :class="[
                      game,
                      dataGroup.last_five_games.length == 5
                        ? 'movil-game-flag-' + index
                        : dataGroup.last_five_games.length > 3
                        ? 'movil-game-flag-' + index + 1
                        : '',
                    ]"
                  >
                    {{ game }}
                  </div>
                </template>
              </template>
              <div v-else>
                -
              </div>
            </div>
            <div class="table-mundial-group__div-table-row__div-table-col prox">
              <template v-if="dataGroup.next_rival_logo != '-'">
                <img
                  :src="'https://az755631.vo.msecnd.net/teams-80/' + dataGroup.next_rival + '.png'"
                  width="22"
                  :alt="dataGroup.next_rival_name"
                />
              </template>
              <template v-else><span>-</span></template>
            </div>
          </div>
        </template>
      </template>
      <template v-else>
        <template v-for="indexPlace in 4">
          <div :key="indexPlace" class="placeholder-item"></div>
        </template>
      </template>
    </div>
    <!-- nombre nombre-->
  </div>
</template>
<script>
export default {
  name: 'TableMundialGroup',
  components: {},
  props: {
    teamselect: { type: Number, default: 0 },
    header: { type: Boolean, default: true },
    season: {
      type: [Number, String],
      default: 0,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  watch: {},
  data() {
    return {
      tableData: [],
    };
  },
  async created() {},
  mounted() {},
  destroyed() {},
  computed: {},
  methods: {
    goToTeamProfile(team) {
      const seasonApi = this.season == null ? 1401 : this.season;
      window.open(`https://golstats.com/teamprofile?team=${team}&season=${seasonApi}`, '_blank');
    },
  },
};
</script>
<style lang="scss" scoped>
.color-green {
  background: #deefae !important;
}
.placeholder-item:nth-of-type(odd) {
  background-color: #f5f5f5;
  box-shadow: 0 1px 2px 0 rgba(33, 33, 33, 0.05);
}
.placeholder-item {
  height: 32px;
  position: relative;
  overflow: hidden;
}
.placeholder-item:nth-of-type(odd)::before {
  content: '';
  display: block;
  position: absolute;
  left: -150px;
  top: 0;
  height: 100%;
  width: 150px;
  background: linear-gradient(to right, transparent 0%, #e8e8e8 50%, transparent 100%);
  animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}
.table-mundial-group {
  width: 100%;
  height: auto;
  &__grouptitle {
    width: 100%;
    height: 36px;
    line-height: 36px;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    font-family: 'Avenir-Pro-Bold';
    letter-spacing: -0.25px;
    text-align: left;
    color: #132839;
    padding-left: 10px;
  }
  &__head {
    display: table;
    height: 20px;
    width: 100%;

    &__div-table-col-head {
      float: left;
      display: table-column;
      width: 10%;
      height: 20px;
      line-height: 20px;
      background: #f2f4f9;
      font-size: 9.7px;
      text-align: center;
      font-family: 'Avenir-Demi';
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.22px;
      color: #132839;
    }

    &__div-table-col-head.position {
      width: 11.2% !important;
      padding-left: 6px;
    }
    &__div-table-col-head.teamtable {
      width: 22.3% !important;
      text-align: center !important;
    }
    &__div-table-col-head.jj {
      width: 6.92% !important;
    }
    &__div-table-col-head.jg {
      width: 6.92% !important;
    }
    &__div-table-col-head.je {
      width: 6.92% !important;
    }
    &__div-table-col-head.jp {
      width: 6.92% !important;
    }
    &__div-table-col-head.gf {
      width: 6.92% !important;
    }
    &__div-table-col-head.gc {
      width: 6.92% !important;
    }
    &__div-table-col-head.dg {
      width: 6.92% !important;
    }
    &__div-table-col-head.pts {
      width: 7% !important;
    }
    &__div-table-col-head.lastgames {
      width: 21.2% !important;
    }
    &__div-table-col-head.prox {
      width: 10.5% !important;
    }
  }
  &__div-table {
    display: table;
    width: 100%;
  }
  &__div-table-row:nth-of-type(even) {
    background-color: #f7faf9;
  }
  &__div-table-row {
    display: table-row;
    width: auto;
    clear: both;
    height: 32px;

    &__div-table-col {
      float: left;
      display: table-column;
      width: 10%;
      height: 32px;
      line-height: 34px;

      &__game {
        width: 16px !important;
        height: 16px !important;
        margin-top: 9px;
        border-radius: 32px;
        margin-left: 2px;
        margin-right: 2px;
        font-family: 'Avenir-Demi';
        font-size: 10px !important;
        line-height: 16px;
        color: #ffffff;
        padding-right: 0px;
      }
      &__game.P {
        background-color: #eb2424;
        padding-left: 0px;
      }
      &__game.G {
        background-color: #73d44e;
        padding-right: 1px;
      }
      &__game.E {
        background-color: #ababab;
      }
    }

    &__div-table-col.position {
      width: 10% !important;
      font-family: 'Avenir-Pro-Medium';
      font-size: 13.2px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.49px;
      color: #132839;
    }
    &__div-table-col.logo {
      width: 10% !important;
      padding-top: 5px;
    }
    &__div-table-col.teamtable {
      width: 12.8% !important;
      text-align: left;
      text-decoration: underline;
      font-family: 'Avenir-Demi';
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: #132839;
      cursor: pointer;
    }
    &__div-table-col.jj {
      width: 7.2% !important;
      font-family: 'Avenir-Pro-Medium';
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      text-align: center;
      letter-spacing: -0.45px;
      color: #132839;
    }
    &__div-table-col.jg {
      width: 7.2% !important;
      font-family: 'Avenir-Pro-Medium';
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      text-align: center;
      letter-spacing: -0.45px;
      color: #132839;
    }
    &__div-table-col.je {
      width: 7% !important;
      font-family: 'Avenir-Pro-Medium';
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      text-align: center;
      letter-spacing: -0.45px;
      color: #132839;
    }
    &__div-table-col.jp {
      width: 7.2% !important;
      font-family: 'Avenir-Pro-Medium';
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      text-align: center;
      letter-spacing: -0.45px;
      color: #132839;
    }
    &__div-table-col.gf {
      width: 7.2% !important;
      font-family: 'Avenir-Pro-Medium';
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      text-align: center;
      letter-spacing: -0.45px;
      color: #132839;
    }
    &__div-table-col.gc {
      width: 6.92% !important;
      font-family: 'Avenir-Pro-Medium';
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      text-align: center;
      letter-spacing: -0.45px;
      color: #132839;
    }
    &__div-table-col.dg {
      width: 7.2% !important;
      font-family: 'Avenir-Pro-Medium';
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      text-align: center;
      letter-spacing: -0.45px;
      color: #132839;
    }
    &__div-table-col.pts {
      width: 7.2% !important;
      font-family: 'Avenir-Pro-Bold';
      font-size: 13.2px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.49px;
      color: #132839;
    }
    &__div-table-col.lastgames {
      width: 22% !important;
      display: flex;
      justify-content: center;
    }
    &__div-table-col.prox {
      width: 9.3% !important;
      padding-top: 5px;
      padding-left: 6px;
    }
  }
  .movil-game-flag-lastgame-movil {
    display: none;
  }
  .movil-game-flag-0,
  .movil-game-flag-1 {
    display: none;
  }
  .movil-game-flag-lastgame-movil {
    display: inline-block;
  }
  .movil-game-flag-lastgame {
    display: none;
  }

  .movil-game-flag-g {
    display: none;
  }
}
</style>
